import { BoxProps, Button, Checkbox, Flex, Input, Text, Textarea } from '@chakra-ui/react'
import { FC, ReactNode, useState } from 'react'
import { FieldErrors, type FieldValues, FormProvider, useForm, UseFormRegister } from 'react-hook-form'
import { Contact, VoteFormData } from '@miitypes'
import { createVote } from '@miimosa/api'

interface Props {
  contact?: Contact
  slug: string
  onSuccess: (data: VoteFormData) => void
  fullname: string
  text: string
  platform: string
  logo: ReactNode
}

async function vote(slug: string, platform: string, data: VoteFormData) {
  return await createVote({
    project_slug: slug,
    firstname: data.firstname,
    lastname: data.lastname,
    email: data.email,
    message: data.message ?? '',
    platform: platform,
    cgu: data.cgu,
  })
}

const FormInput = ({
  name,
  placeholder,
  register,
  errors,
  required = false,
}: {
  name: string
  placeholder: string
  register: UseFormRegister<any>
  errors: FieldErrors<any>
  required: boolean
}) => {
  return (
    <Flex direction="column" mr="4" position="relative">
      <Input
        placeholder={errors[name] ? placeholder + ' (obligatoire)' : placeholder}
        border={errors[name] ? 'solid 1px red' : 'none'}
        {...register(name, { required: required && 'Ce champ est obligatoire' })}
      />
      {errors[name] && (
        <Flex
          zIndex={2}
          bgColor="error"
          align="center"
          justify="center"
          width="20px"
          height="20px"
          position="absolute"
          top="5px"
          right="5px"
        >
          <Text color="red">*</Text>
        </Flex>
      )}
    </Flex>
  )
}

export const Vote: FC<Props & BoxProps> = ({ slug, onSuccess, contact, fullname, text, platform, logo }) => {
  const methods = useForm({ reValidateMode: 'onBlur', shouldUnregister: true, defaultValues: contact as any })
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods
  const [submitState, setSubmitState] = useState<undefined | 'loading' | 'failed'>()

  // eslint-disable-next-line
  const onSubmit = (formData: FieldValues) => {
    setSubmitState('loading')
    vote(slug, platform, formData as VoteFormData)
      .then((res) => {
        if (res) onSuccess(formData as VoteFormData)
        else setSubmitState('failed')
      })
      .catch(() => setSubmitState('failed'))
  }

  const inputLength = (watch('message', '') as string)?.length

  const renderError = (name: string) => (
    <Text color="error" mt="2" size="3xs">
      {errors[name]?.message as string}
    </Text>
  )

  // eslint-disable-next-line
  return (
    <Flex direction="column" align="center" fontSize={16}>
      {logo}
      <FormProvider {...methods}>
        <Flex as="form" onSubmit={handleSubmit(onSubmit)} direction="column" align="start" position="relative">
          <Flex columnGap={{ base: 2, md: 0 }} align="center" mb={4}>
            <FormInput name="firstname" placeholder="Votre prénom" register={register} errors={errors} required />
            <FormInput name="lastname" placeholder="Votre nom" register={register} errors={errors} required />
          </Flex>

          <Flex mb={4}>
            <FormInput name="email" placeholder="Votre email" register={register} errors={errors} required />
          </Flex>

          <Flex fontStyle="italic" fontSize={16} mb={4} dangerouslySetInnerHTML={{ __html: text }} />

          <Text size="md" mb={4}>
            Je soutiens et apporte mon vote au projet de{' '}
            <Text as="span" fontWeight="bold">
              {fullname}
            </Text>
            .
          </Text>

          <Flex direction="column" width={'100%'} mb={4}>
            <Textarea
              variant="outline"
              height="110px"
              {...register('message', { maxLength: 240 })}
              placeholder="Votre message de soutien (facultatif)"
            />
            {errors['message'] && renderError('message')}
            <Flex mt="2" justify="space-between" align="center">
              <Flex>
                <Text size="xs" color="#757575" display="flex" align="center">
                  <span style={{ color: inputLength > 240 ? '#F64C46' : '#00B077' }}>{inputLength}</span>
                  /240
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex align="center" mt={2}>
            <Checkbox size="sm" {...register('cgu', { required: 'Vous devez accepter les CGU' })}>
              En cochant cette case, j’accepte les conditions de l’opération (voir le règlement)
            </Checkbox>
          </Flex>

          <Flex>
            <Flex fontSize={14} color="red" position="absolute">
              {errors['cgu'] ? renderError('cgu') : ''}
            </Flex>
          </Flex>

          {/* submit button */}
          <Button type="submit" variant="brandPrimary" size="sm" mt={{ base: 6, md: 12 }} mx="auto">
            Je vote
          </Button>
          {submitState == 'failed' && (
            <Text as="span" color="red" mx="auto">
              Une erreur est survenue, merci de réessayer
            </Text>
          )}
        </Flex>
      </FormProvider>
    </Flex>
  )
}
