import { HamburgerIcon } from '@chakra-ui/icons'
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  useBreakpointValue,
  useDisclosure,
  Box,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Accordion,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react'
import React from 'react'

import { Link, Icon } from '@miimosa/design-system'

export const Header = () => {
  // note: since base font size can change, em size can change thus all is specified in pixels for the Header
  // (because it's common to multiple themes)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'lg' })

  return isMobile ? (
    <Flex
      w="full"
      bg="white"
      position="sticky"
      top="0"
      zIndex={10}
      direction="row"
      h="60px"
      justifyContent="space-between"
      px="20px"
      alignItems="center"
      fontFamily="'NestleText', sans-serif"
    >
      <Flex alignItems="center">
        <Link href="/">
          <Icon name="GenerationRegeneration" boxSize="110px" h="auto" color="#63513D" />
        </Link>
        <Box textColor="black" alignItems="end" fontSize="18px" fontFamily="Arial, sans serif">
          &nbsp; &amp; &nbsp;
        </Box>
        <Link href="https://www.miimosa.com">
          <Icon name="Logo2" boxSize="110px" h="auto" color="#003F2C" />
        </Link>
      </Flex>
      <IconButton
        aria-label="Button to open menu"
        onClick={onOpen}
        bgColor="white"
        icon={<HamburgerIcon boxSize="32px" bgColor="white" color="black" />}
      />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
        <DrawerOverlay />
        <DrawerContent py="24px" fontSize="18px">
          <DrawerCloseButton />
          <DrawerBody display="flex" rowGap="32px" flexDirection="column">
            <Link onClick={onClose} href="#demarche">
              La démarche
            </Link>

            <Accordion allowToggle>
              <AccordionItem border="none">
                <AccordionButton
                  justifyContent="space-between"
                  m={0}
                  p={0}
                  _hover={{ bgColor: 'white' }}
                  fontSize="18px"
                >
                  <>Les opérations</>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb="16px">
                  <UnorderedList styleType="''" spacing="16px">
                    <ListItem>
                      <Link onClick={onClose} href="/friskies">
                        FRISKIES®
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link onClick={onClose} href="/ricore">
                        RICORÉ®
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link onClick={onClose} href="/nestle">
                        NESTLÉ® CÉRÉALES
                      </Link>
                    </ListItem>
                  </UnorderedList>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            <Link onClick={onClose} href="#agriculteurs">
              Les agriculteurs partenaires
            </Link>
            <Link onClick={onClose} href="#engagements">
              Les engagements
            </Link>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  ) : (
    <Flex
      w="full"
      h="50px"
      py="8px"
      px="8px"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      bg="white"
      position="sticky"
      top="0"
      zIndex={10}
      fontFamily="'NestleText', sans-serif"
    >
      <Flex mr="16px" whiteSpace="nowrap" alignItems="center">
        <Link href="/">
          <Icon name="GenerationRegeneration" boxSize="120px" h="auto" color="#63513D" />
        </Link>
        <Box textColor="black" alignItems="end" fontSize="18px" fontFamily="Arial, sans serif">
          &nbsp; &amp; &nbsp;
        </Box>
        <Link href="https://www.miimosa.com">
          <Icon name="Logo2" boxSize="120px" h="auto" color="#003F2C" />
        </Link>
      </Flex>
      <Flex direction="row" columnGap="32px" alignItems="center" fontWeight={400} fontSize="16px">
        <Link onClick={onClose} href="#demarche" whiteSpace="nowrap">
          La démarche
        </Link>
        <Link onClick={onClose} href="/#operations" whiteSpace="nowrap">
          Les opérations
        </Link>
        <Link onClick={onClose} href="#agriculteurs" whiteSpace="nowrap">
          Les agriculteurs partenaires
        </Link>
        <Link onClick={onClose} href="#engagements" whiteSpace="nowrap">
          Les engagements
        </Link>
      </Flex>
    </Flex>
  )
}

export default Header
