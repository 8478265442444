import { Text } from '@chakra-ui/react'

export const MiimosaCestQuoi = {
  title: 'MiiMOSA, c’est quoi ?',
  text: (
    <>
      <Text>
        MiiMOSA est la plateforme de financement leader en Europe dédiée à la transition agricole et alimentaire. Après
        avoir accompagné 7 000 projets pour 150 millions d’euros de financement (en France et en Belgique), MiiMOSA a
        lancé en janvier 2022 le premier fonds de dette dédié à la thématique.
        <br />
        <br />
        Labellisée BCorp, l’entreprise s’engage à recréer du lien entre des porteurs de projet et l’ensemble de la
        société, afin que chacun participe à la résolution des enjeux alimentaires, environnementaux, climatiques,
        sanitaires et énergétiques.
      </Text>
    </>
  ),
}

export const FinancementParticipatif = {
  title: 'Qu’est ce que le financement participatif ?',
  text: (
    <>
      <Text>
        Le financement participatif (crowdfunding en anglais) est un mode de financement permettant au grand public de
        soutenir financièrement et collectivement des projets qui le séduisent.
        <br />
        <br />
        MiiMOSA utilise deux offres de financement participatif : le don avec contrepartie et le prêt rémunéré. Le
        financement participatif permet également à des acteurs professionnels (investisseurs, entreprises, etc.) de
        soutenir les projets.
      </Text>
    </>
  ),
}
